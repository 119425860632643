$primaryGradient: linear-gradient(
  149deg,
  rgba(255, 50, 159, 1) 0%,
  rgba(255, 50, 159, 1) 35%,
  rgba(0, 180, 209, 1) 100%
);
$primary: #ff329f;
$secondary: #00b4d0;
$white: #fff;
$backgroundSecondary: #f6f6f6;

/* BLACK BACKGROUNDS COLORS */

$primaryGradientBlack: linear-gradient(5deg, #ff329f 20%, #020505 100%);
$backgroundPrimaryBlack: rgb(0, 0, 0);
$backgroundSecondaryBlack: black;
