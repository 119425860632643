//get local storage value
@import '../styles/variables.scss';

body,
html {
  font-family: 'Open Sans', sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
}

.App {
  h2,
  h3,
  h4 {
    font-family: 'Raleway', sans-serif;
  }
  h2 {
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 36px;
    color: #333;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  h4 {
    font-size: 18px;
    color: #333;
    font-weight: 600;
  }
  h5 {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 20px;
  }
  p {
    font-size: 15px;
  }
  p.intro {
    margin: 12px 0 0;
    line-height: 24px;
  }
  a {
    color: $primary;
    font-weight: 400;
  }
  a:hover,
  a:focus {
    text-decoration: none;
    color: $primary;
  }
  ul,
  ol {
    list-style: none;
  }
  ul,
  ol {
    padding: 0;
  }
  hr {
    height: 2px;
    width: 70px;
    text-align: center;
    position: relative;
    background: #1e7a46;
    margin-bottom: 20px;
    border: 0;
  }
  /* Navigation */
  #menu {
    transition: all 0.8s;
  }
  #menu.active-false {
    background-color: rgb(0, 0, 0);
    opacity: 0;
  }
  #menu.active-true {
    background-color: $white;
    border-color: rgba(231, 231, 231, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  #menu a.navbar-brand {
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
  }
  #menu.navbar-default .navbar-nav > li > a {
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    color: #555;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 2px;
    border-radius: 0;
    margin: 9px 20px 0 20px;
  }
  #menu.navbar-default .navbar-nav > li > a:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 2px;
    background: $primaryGradient;
    content: '';
    transition: width 0.2s;
  }
  #menu.navbar-default .navbar-nav > li > a:hover:after {
    width: 100%;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent;
  }
  .navbar-default .navbar-nav > .active > a:after,
  .navbar-default .navbar-nav > .active > a:hover:after,
  .navbar-default .navbar-nav > .active > a:focus:after {
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    bottom: -1px !important;
    width: 100% !important;
    height: 2px !important;
    background: $primaryGradient !important;
    content: '' !important;
    transition: width 0.2s !important;
  }
  .navbar-toggle {
    border-radius: 0;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: $white;
    border-color: #608dfd;
  }
  .navbar-default .navbar-toggle:hover > .icon-bar {
    background-color: #608dfd;
  }

  .navbar-header {
    width: 100%;
  }
  .section-title {
    margin-bottom: 70px;
  }
  .section-title h2 {
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .section-title h2::after {
    position: absolute;
    content: '';
    background: $primaryGradient;
    height: 4px;
    width: 60px;
    bottom: 0;
    margin-left: -30px;
    left: 50%;
  }
  .section-title p {
    font-size: 18px;
  }
  .btn-custom {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    color: $white;
    background-color: #5ca9fb;
    background-image: $primaryGradient;
    padding: 14px 34px;
    letter-spacing: 1px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
  }
  .btn-custom:hover,
  .btn-custom:focus,
  .btn-custom.focus,
  .btn-custom:active,
  .btn-custom.active {
    color: $white;
    background-image: none;
    background-color: #6372ff;
  }
  .btn:active,
  .btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  a:focus,
  .btn:focus,
  .btn:active:focus,
  .btn.active:focus,
  .btn.focus,
  .btn:active.focus,
  .btn.active.focus {
    outline: none;
    outline-offset: none;
  }
  /* Header Section */
  #welcome,
  .intro {
    background-image: url('../brocheBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: border-box;
  }

  .intro {
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;

    .container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: -5%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 0 15px;
    }
  }
  .intro video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .intro .overlay {
    background: rgba(0, 0, 0, 0.2);
  }
  .intro h1 {
    font-family: 'Raleway', sans-serif;
    color: $white;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  .intro p {
    color: $white;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  header .intro-text {
    padding-top: 350px;
    padding-bottom: 200px;
    text-align: center;
  }
  /* Features Section */
  #features {
    padding: 100px 0;
    background: $backgroundSecondary;
  }
  #features i.fa {
    font-size: 38px;
    margin-bottom: 20px;
    transition: all 0.5s;
    color: $white;
    width: 100px;
    height: 100px;
    padding: 30px 0;
    border-radius: 50%;
    background: $primary;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  }
  /* About Section */
  #about {
    padding: 100px 0;
  }
  #about h3 {
    font-size: 22px;
    margin: 0 0 20px 0;
  }
  #about h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  #about h2::after {
    position: absolute;
    content: '';
    background: $primaryGradient;
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
  }
  #about .about-text li {
    margin-bottom: 6px;
    margin-left: 6px;
    list-style: none;
    padding: 0;
  }
  #about .about-text li:before {
    content: '\f00c';
    font-family: 'FontAwesome';
    color: #5ca9fb;
    font-size: 11px;
    font-weight: 300;
    padding-right: 8px;
  }
  #about img {
    width: 520px;
    margin-top: 10px;
    background: $white;
    border-right: 0;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
  }
  #about p {
    line-height: 24px;
    margin: 30px 0;
  }
  /* Services Section */
  #services {
    padding: 100px 0;
    background: $primaryGradient;
    color: $white;
    height: auto;
  }
  #services .service-desc {
    margin: 10px 10px 20px;
  }
  #services h2 {
    color: $white;
  }
  #services .section-title h2::after {
    position: absolute;
    content: '';
    background: rgba(255, 255, 255, 0.3);
    height: 4px;
    width: 60px;
    bottom: 0;
    margin-left: -30px;
    left: 50%;
  }
  #services i.fa {
    font-size: 42px;
    width: 120px;
    height: 120px;
    padding: 40px 0;
    background: $primary;
    border-radius: 50%;
    color: $white;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  }
  #services h3 {
    font-weight: 500;
    padding: 5px 0;
    color: $white;
  }
  #services p {
    color: rgba(255, 255, 255, 0.75);
  }
  #services .service-desc {
    margin-bottom: 40px;
  }
  /* Portfolio Section */
  #portfolio {
    padding: 100px 0;
  }
  .portfolio-item {
    margin: 1px -15px 0 -14px;
    padding: 0;
  }
  .portfolio-item .hover-bg {
    overflow: hidden;
    position: relative;
    margin: 0;
  }
  .hover-bg .hover-text {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    color: $white;
    background: $primaryGradient;
    padding: 30% 0 0 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
  }
  .hover-bg .hover-text > h4 {
    opacity: 0;
    color: $white;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: all 0.3s;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .hover-bg:hover .hover-text > h4 {
    opacity: 1;

    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .hover-bg:hover .hover-text {
    opacity: 1;
  }
  /* Testimonials Section */
  #testimonials {
    padding: 100px 0;
    background: #f6f6f6;
  }
  #testimonials i {
    color: #e6e6e6;
    font-size: 32px;
    margin-bottom: 20px;
  }
  .testimonial {
    position: relative;
    padding: 20px;
  }
  .testimonial-image {
    float: left;
    margin-right: 15px;
  }
  .testimonial-image,
  .testimonial-image img,
  .testimonial-image div {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  .testimonial-content {
    position: relative;
    overflow: hidden;
  }
  .testimonial-content p {
    margin-bottom: 0;
    font-size: 14px;
    font-style: italic;
  }
  .testimonial-meta {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #666;
  }
  /* Team Section */
  #team {
    padding: 100px 0;
  }
  #team h4 {
    margin: 5px 0;
  }
  #team .team-img {
    width: 240px;
  }
  #team .thumbnail {
    background: transparent;
    border: 0;
  }
  #team .thumbnail .caption {
    padding: 10px 0 0 0;
    color: #888;
  }
  /* Contact Section */
  #contact {
    padding: 100px 0 60px 0;
    background: $primaryGradient;
    color: rgba(255, 255, 255, 0.75);
  }
  #contact .section-title {
    margin-bottom: 40px;
  }
  #contact .section-title p {
    font-size: 16px;
  }
  #contact h2 {
    color: $white;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  #contact .section-title h2::after {
    position: absolute;
    content: '';
    background: rgba(255, 255, 255, 0.3);
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 30px;
  }
  #contact h3 {
    color: $white;
    margin-top: 80px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    font-weight: 400;
  }
  #contact form {
    padding-top: 20px;
  }
  #contact .text-danger {
    color: #cc0033;
    text-align: left;
  }
  #contact .btn-custom {
    margin: 30px 0;
    background: transparent;
    border: 2px solid $white;
  }
  #contact .btn-custom:hover {
    color: #1f386e;
    background: $white;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    float: left;
  }
  #contact .form-control {
    display: block;
    width: 100%;
    margin-top: 5%;
    padding: 12px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: $white;
    background-color: rgba(255, 255, 255, 0.201);
    background-image: none;
    border-radius: 25px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;

    &::placeholder {
      color: $white !important;
      opacity: 1;
    }
  }
  #contact .form-control:focus {
    border-color: #999;
    outline: 0;
    -webkit-box-shadow: transparent;
    box-shadow: transparent;
  }
  .form-control::-webkit-input-placeholder {
    color: #777;
  }
  .form-control:-moz-placeholder {
    color: #777;
  }
  .form-control::-moz-placeholder {
    color: #777;
  }
  .form-control:-ms-input-placeholder {
    color: #777;
  }
  #contact .contact-item {
    margin: 20px 0;
  }
  #contact .contact-item span {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    display: block;
  }
  #contact .contact-item i.fa {
    margin-right: 10px;
  }
  #contact .social {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 50px;
    margin-top: 50px;
    text-align: center;
  }
  #contact .social ul li {
    display: inline-block;
    margin: 0 20px;
  }
  #contact .social i.fa {
    font-size: 22px;
    width: 48px;
    height: 48px;
    padding: 12px 0;
    border: 2px solid $white;
    color: $white;
    border-radius: 50%;
    transition: all 0.3s;
  }
  #contact .social i.fa:hover {
    color: $primary;
    background: $white;
  }
  /* Footer Section*/
  #footer {
    background: $white;
    padding: 30px 0;
  }
  #footer p {
    color: #888;
    font-size: 14px;
  }
  #footer a {
    color: $primary;
  }
  #footer a:hover {
    border-bottom: 2px solid #608dfd;
  }

  #gallery {
    padding: 10% 0;
  }

  @media (max-width: 768px) {
    #about img {
      margin: 50px 0;
    }
  }

  .cursor {
    cursor: pointer;
  }

  .container-logo {
    width: 50%;
  }

  .contact-item.whatsapp:hover {
    color: green !important;

    i,
    span,
    p {
      color: rgb(27, 208, 27) !important;
      transition: 0.5s;
    }
  }

  .loader {
    border-top: 8px solid hotpink;
    border-bottom: 8px solid #f55951;
    border-left: 8px solid #3a9efd;
    border-right: 8px solid #1fff7c;
    border-radius: 100%;
    height: 100px;
    width: 100px;
    animation: looper 5s infinite ease-in-out;
  }

  @keyframes looper {
    0% {
      border-radius: 100% 100% 100% 100%;
      border-color: hotpink #3a9efd #1fff7c #f55951;
    }

    10% {
      border-radius: 0% 100% 0% 0%;
      border-color: #f55951;
      transform: rotate(150deg);
    }

    20% {
      border-radius: 0% 0% 0% 0%;
      border-color: #3a9efd;
    }

    30% {
      border-radius: 0% 0% 0% 0%;
      border-color: #1fff7c;
    }

    40% {
      border-radius: 0% 0% 0% 0%;
      border-color: #3a9efd;
    }

    50% {
      border-radius: 100%;
      border-color: #f55951;
    }

    90% {
      border-radius: 100%;
      transform: rotate(360deg);
      border-style: dashed;
    }

    100% {
      transform: rotate(0deg);
      border-radius: 100% 100% 100% 100%;
      border-color: hotpink #3a9efd #1fff7c #f55951;
    }
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.m-0 {
  margin: 0;
}

.fade-in {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.fade-in-2 {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
