@import "../styles/variables.scss";

.App.dark {
  background-color: $primaryGradientBlack;

  #features,
  #about,
  #portfolio,
  #testimonials,
  #footer {
    background-image: url("../../../public/img/bg/blurry-gradient-haikei2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    p {
      color: white;
    }
  }

  #services,
  #contact {
    background: $primaryGradientBlack;
  }

  h3,
  h2 {
    opacity: 0.7;
    color: $white;
  }

  p,
  li {
    color: $white;
  }

  .navbar,
  #menu.active-true {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
